












import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'
import { format, preventHtmlTags } from '@/utils'

@Component({
  components: {

  },
})
export default class MeetingDescription extends Vue {
  @Prop({
    required: true,
    type: Meeting,
  }) meeting!: Meeting

  get formattedText () {
    return format(preventHtmlTags(this.meeting.description ?? ''), false, true)
  }
}
